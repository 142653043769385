import api from './api'

export async function getEvents(category, date) {
    const data = await api.get(`${process.env.API_URL}/events?` + new URLSearchParams({
        category: category,
        month: date.month,
        year: date.year
    }))
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data
}